import axios from 'axios'
import { useEffect, useState } from 'react'
import { headers } from '../utility/proxyConfig'

const useFetch = (url: string) => {
  const [data, setData] = useState<any>(null)
  const [results,setresults]=useState<any>(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState<null|boolean>(null)

  useEffect(() => {
    (async () => {
      setLoading(true)
      setData(null)
      setError(null)
      try {
        const result = await axios.get(url, { headers })
        if(result === null || result.data === null || result.data === '' || result.data === undefined)  {
          setLoading(false)
        }
        setData(result.data)
        setresults(result)
      } catch (err: any) {
        setLoading(false)
        setError(err.message || 'Unexpected Error!')
      }
    })()
  }, [url])

  return {
    results,
    data,
    error,
    loading
  }
}

export default useFetch
