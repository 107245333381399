import Styles from './index.module.css'
import Image from '../Image'
import Link from '../Link'

type propsType = {
  contents: {
    title: string
    cta?: {
      title: string
      url: {
        url: string
      }[]
    }
    images:  {
      title: string
      url: string
    }[]
    background_color?: boolean
  }
}
const TextMultiImageDark:React.FC<propsType>= (props: propsType) => {
  const { contents } = props

  return (
    <div className={`mt-24 ${Styles.textMultiImageDark}`} data-test='textMultiImageDarkContainer'>
      <div className='pt-32 overflow-hidden sm:pt-14'>
        <div className={Styles.bgColor}>
          <div className='container'>
            <div className='relative pt-44 pb-24 lg:py-36 sm:py-24'>
              <div className={`sm:max-w-sm ${Styles.textContainer}`}>
                {contents.title ? (
                  <h1 data-test='title'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    {...contents?.$?.title}
                  >{contents.title}</h1>
                ) : (
                  ''
                )}
                {contents?.cta?.url?.length && contents.cta.title ? (
                  <div className='mt-6' data-test='cta'>
                    <Link href={contents.cta.url[0].url}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      {...contents?.cta?.$?.title}
                      className='hover:opacity-75'>
                      {contents.cta.title}
                      <span aria-hidden='true'>
                        {' '}
                        &rarr;
                      </span>
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </div>

              {contents.images?.length ? (
                <div className={`absolute -top-32 left-1/2 transform -translate-x-1/2 sm:top-6 sm:translate-x-0 ${Styles.imageContainer}` } data-test='imageSection'>
                  <div className='ml-24 flex space-x-6 min-w-max sm:ml-3 lg:space-x-8'>
                    <div className='flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8'>
                      {contents.images.slice(0, 2).map(
                        (img, index) =>
                          img.url && (
                            <div
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              {...img?.$?.url}
                              className={
                                index === 0
                                  ? 'flex-shrink-0'
                                  : 'mt-6 flex-shrink-0 sm:mt-0'
                              }
                              data-test='imageUrl'
                              key={index}
                            >
                              <Image
                                contents = {{
                                  src: img.url,
                                  alt: img.title,
                                  className: `h-64 w-64 rounded-lg object-cover md:h-72 md:w-72 ${Styles.primaryImage}`,
                                  maxWidth: '288'
                                }}
                              />
                            </div>
                          )
                      )}
                    </div>
                    <div className='flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8'>
                      {contents.images.slice(2, 4).map(
                        (img, index) =>
                          img.url && (
                            <div
                              key={index}
                              className={
                                index === 3
                                  ? 'flex-shrink-0'
                                  : 'mt-6 flex-shrink-0 sm:mt-0'
                                
                              }
                              data-test='imageUrl'
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              {...img?.$?.url}
                            >
                              <Image
                                contents = {{
                                  src: img.url,
                                  alt: img.title,
                                  className: `h-64 w-64 rounded-lg object-cover md:h-72 md:w-72 ${Styles.primaryImage}`,
                                  maxWidth: '288'
                                }}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TextMultiImageDark
