/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, createContext } from 'react'

interface ErrorContextType {
  Err: string|null;
  updateErr: (value: string) => void;
}

const ErrorContext = createContext<ErrorContextType>({} as ErrorContextType)

export const ErrorCtxProvider: React.FC = ({ children }) => {
  const [Err, setError] = useState<string|null>(null)

  const updateErr = (value: string ) => {
    setError(value)
  }

  return (
    <ErrorContext.Provider
      value={{
        Err,
        updateErr
      }}
    >
      {children}
    </ErrorContext.Provider>
  )
}

export const useErrorCtx = () => React.useContext(ErrorContext)

