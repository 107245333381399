import {
  Context,
  ManifestV2,
  enableContextDevTools,
  ContextPlugin,
  enableDebugConsoleLogDrain
} from '@uniformdev/context'
import manifest from '../lib/contextManifest.json'
  
const createUniformContext = () => {
  const plugins: ContextPlugin[] = [
    enableContextDevTools(),
    enableDebugConsoleLogDrain('debug')
  ]
  
  const context = new Context({
    defaultConsent: true,
    manifest: manifest as ManifestV2,
    plugins: plugins
  })
  
  return context
}

const context = createUniformContext()
export default context