/* eslint-disable max-len */
import React, {useEffect, useState, Suspense} from 'react'
import { useParams } from 'react-router-dom'
import * as Contentstack from  'contentstack'
import Banner from '../../component/common/Banner'
import TextCta from '../../component/common/TextCta/index'
import TextMultiImageDark from '../../component/common/TextMultiImageDark'
import ImageCardM from '../../component/common/ImageCardMedium'
import TextAsset from '../../component/common/TextAsset'
import style from './index.module.css'
import { getURL, headers } from '../../utility/proxyConfig'
import { useErrorCtx } from '../../context/ErrorContext'
import { getLanguageCode, useLocaleCtx } from '../../context/LocaleCtx'
import Error from '../Error'

import axios from 'axios'
import ContentstackLivePreview from '@contentstack/live-preview-utils'
import { stack } from '../../App'
import Seo from '../../seo'
import { modularBlock } from './index.types'
import Video from '../../component/common/Video'

// const ImageCardXL = React.lazy(()=> import('../../component/common/ImageCardXL/index'))
const TextMultiImageLight = React.lazy(() => import('../../component/common/TextMultiImageLight'))



const Home: React.FC = () => {
  
  
  const env = process.env.REACT_APP_ENV || 'development'
  const { lang } = useParams()
  const {locale , updateLocale} = useLocaleCtx()
  const { updateErr} = useErrorCtx()

  const [response, setData] = useState<any>({})
  const [hotspot,sethotspot]=useState<any>({})
  
  const [Err, setErr] = useState<string>('')
  
  async function fetchData () {
    ContentstackLivePreview.onEntryChange(async () => {
      try {
        const refUids = 'content_block.banner.banner'
        const url= stack.live_preview?.hash ? getURL(`/content/homepage?locale=${getLanguageCode(locale)}&refUids=${refUids}
        &env=${env}&live_preview=${stack.live_preview?.hash}&content_type_uid=${stack.live_preview?.content_type_uid}&entry_uid=${stack.live_preview?.entry_uid}&include_metadata=true`)
          : getURL(`/content/homepage?locale=${getLanguageCode(locale)}&refUids=${refUids}&env=${env}&include_metadata=true`)
        const res = await axios(url, {headers})
        const finalResult = await res.data
        console.log('🚀 ~ file: index.tsx:60 ~ ContentstackLivePreview.onEntryChange ~ finalResult:', finalResult)
        if(finalResult) setData(finalResult)
      } catch(err:any) {
        setErr(`${err.message}`)
      }
    })
  }
  
  useEffect(() => {
    if(lang) { updateLocale(lang) }
    fetchData()
  
  }, [locale])
    


  const renderOptions = {
    a: (asset: any, metadata: any) => {
      return (
        `<a href=${asset?.attrs?.url} target=${asset?.attrs?.target}>${asset?.children.length>0&&asset?.children[0]?.text}</a>`
      )
    }
  }

  if (response) {
    Contentstack.Utils.jsonToHTML({ 
      entry: response, 
      paths: ['content_block.banner.banner.paragraph'],
      renderOption: renderOptions
    }) 
  }
  
  // eslint-disable-next-line no-underscore-dangle
  const renderBlocks = (block: modularBlock) => {
    switch (Object.keys(block)[0]) {
    case 'text_cta':
      return (
        <TextCta
          contents={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            $: block.text_cta.$,
            title: block.text_cta.title,
            paragraph: block.text_cta.paragraph,
            backgroundColor: true,
            cta: block.text_cta?.cta?.title
              ? block.text_cta.cta
              : {}
          }}
        />
      )
    
    case 'text_multi_image':
      return block.text_multi_image.theme === 'dark' ? (
        <TextMultiImageDark
          contents = {{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            $: block.text_multi_image.$,
            title: block.text_multi_image.title,
            cta: block.text_multi_image?.cta?.title
              ? block.text_multi_image.cta
              : undefined,
            images: block.text_multi_image?.images?.length
              ? block.text_multi_image.images
              : []
          }}
        />
      ) : (
        <Suspense fallback = {<div>Loading............</div>}>
          <TextMultiImageLight
            contents = {{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
              $: block.text_multi_image.$,
              title: block.text_multi_image.title,
              paragraph: block.text_multi_image.paragraph,
              cta: block.text_multi_image?.cta?.title
                ? block.text_multi_image.cta
                : undefined,
              images: block.text_multi_image?.images?.length
                ? block.text_multi_image.images
                : []
            }}
          />
        </Suspense>
      )
    
    case 'text_image_card':
      // console.log(block.text_image_card)
      return (
        <div className='mt-24'>
          <ImageCardM
            contents={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              $: block.text_image_card.$,
              title: block.text_image_card.title,
              paragraph: block.text_image_card.paragraph,
              image_section: block.text_image_card?.image_cards?.card?.length
                ? block.text_image_card.image_cards.card
                : []
            }}
          />  
        </div>
      )
      //         )
      //       )
      //     )
      // )
    
    case 'text_asset':
      return (
        <TextAsset
          contents={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            $: block.text_asset.$,
            title: block.text_asset.title,
            paragraph: block.text_asset.paragraph,
            top_heading: block.text_asset.subtitle,
            image: block.text_asset.image,
            video_url: block.text_asset.video_url,
            textAlign: block.text_asset.text_align,
            classStyle: style.textAsset,
            dark_mode: block?.text_asset?.dark_mode

            
          }}
        />
      )

    case 'video':
      // (console.log( block?.video?.dam_video?.[0]?.url))
      return (<Video
        contents={{dam_video: block?.video?.dam_video?.[0]?.url}} />  
      )

    case 'banner':
      // eslint-disable-next-line no-case-declarations
      const banner = block.banner.banner[0]
      return (
        banner && <Banner
          title={banner?.title}
          paragraph={banner?.paragraph}
          background_image={banner?.background_image || ''}
          cta={banner.cta ? banner.cta : []}
          $={banner?.$}
        />
      )
    
    default:
      return null
    }
  }
  
  return (    
    <>
      {response && <div className='landing-page'>
        <Seo
          contents = {{
            title: response?.seo_and_search?.title,
            description: response?.seo_and_search?.description,
            canonical_url: response?.seo_and_search?.canonical_url,
            og_meta_tags: response?.seo_and_search?.og_meta_tags,
            no_follow: response?.seo_and_search?.no_follow,
            no_index: response?.seo_and_search?.no_index,
            show_in_site_search: response?.seo_and_search?.show_in_site_search,
            show_in_sitemap: response?.seo_and_search?.show_in_sitemap
          }}
        />
        <body data-pageref={response.uid} data-contenttype='landing_page' data-locale={getLanguageCode(locale)} >
      
          {response?.content_block?.length
          && response.content_block.map((block: modularBlock) => renderBlocks(block))}
        </body>
      </div>
      }
      
      { Err &&  <> { !getLanguageCode(locale) ? updateErr('') : updateErr(Err) }
        <Error />
      </>}
    </>
  )
}
export default Home

