/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, createContext } from 'react'
import { useLocation } from 'react-router-dom'
import { getLocaleFromPath } from '../utility'


interface LocaleContextType {
  locale: string;
  updateLocale: (value: string) => void;
}

export const getLanguageCode = (loc :string):string|undefined => {
  const langCodes = {
    'en': 'en-us',
    'fr': 'fr-fr',
    'de': 'de-de',
    'jp': 'ja-jp',
    'kr': 'ko-kr',
    'cn': 'zh-cn',
    'es': 'es-mx',
    'pt': 'pt-br'
  }
  for (const [key, value] of Object.entries(langCodes)) {
    if(key === loc){
      return value
    }
  }
}

export const defaultLocale = 'en'

const LocaleContext = createContext<LocaleContextType>({} as LocaleContextType)

export const LocaleCtxProvider: React.FC = ({ children }) => {
  const {pathname} = useLocation()
  const currLocale = getLocaleFromPath(pathname)  
  const [locale, setLocale] = useState(currLocale)

  const updateLocale = (value: string ) => {
    setLocale(value)
  }

  return (
    <LocaleContext.Provider
      value={{
        locale,
        updateLocale
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocaleCtx = () => React.useContext(LocaleContext)
