import { useLocaleCtx, defaultLocale } from '../../context/LocaleCtx'


const Link = (props:any) => {
  
  const {locale} = useLocaleCtx()
  const href = props?.href && props?.href?.indexOf('/') !== 0 ? `/${props.href}` : props.href 
  return(
    locale === '' || locale === undefined || locale === defaultLocale
      ? href !== undefined && href !== false
        ?<a
          {...props} 
          href={`${href}`}
        > 
          {props.children}
        </a>
        :<a
          {...props} 
        > 
          {props.children}
        </a>
      : href !== undefined && href !== false
        ? <a
          {...props}
          href={`/${locale}${href}`}
        > 
          {props.children}
        </a>
        :<a
          {...props}
        > 
          {props.children}
        </a>
  )
}
export default Link