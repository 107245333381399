import useFetch from '../../../customhooks/useFetch'
import { getURL } from '../../../utility/proxyConfig'
import ReactHtmlParser from 'react-html-parser'
import styles from './index.module.css'
import * as Contentstack from  'contentstack'
import Image from '../Image'
import { getLanguageCode, useLocaleCtx } from '../../../context/LocaleCtx'
import { useEffect } from 'react'
import { useState } from 'react'
import Link from '../Link'

interface socialLink {
  title: string
  url: string
  icon: {url: string}
  // icon_cta: {title:string, url: string}, icon: {url: string}
}
interface dataLink {cta: {url: {url: string}[],title: string},sub_links:{title_link:[]}}

const Footer:React.FC = () => {
  const {locale} = useLocaleCtx()
  const [submit, setSubmit] = useState(false)

  const refFields = ''
  const {data} = useFetch(getURL(`/content/footer?refUids=${refFields}&locale=${getLanguageCode(locale)}`))
  const response = data
  console.log('🚀 ~ file: index.tsx:22 ~ response:', response)

  useEffect(() => {
    // 
  },[locale])

  // if (response) {
  //   Contentstack.Utils.jsonToHTML({ 
  //     entry: response, 
  //     paths: ['subscription_form.title_text.paragraph']
  //   }) 
  // }
  
  const onsubmit = () => {
    const email = document.getElementById('email-address') as HTMLInputElement
    const firstName = document.getElementById('first-name') as HTMLInputElement
    const lastName = document.getElementById('last-name') as HTMLInputElement
    const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
    if(!submit && email?.value !== '' && emailRegex.test(email.value)) {
      setSubmit(true)
    } else {
      setSubmit(false)
      !emailRegex.test(email.value) ? email.style.borderColor= 'red' : ''
      firstName?.value === '' ? firstName.style.borderColor = 'red' : firstName.removeAttribute('style')
      lastName?.value === '' ? lastName.style.borderColor = 'red' : lastName.removeAttribute('style') 
    }
  }
  
  return (
    <footer className='text-white footer border-t border-gray-200' aria-labelledby='footer-heading'>
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='container py-12 lg:py-16'>
        <div className='xl:grid xl:grid-cols-3 xl:gap-8 '>
          <div className='grid grid-cols-2 gap-8 xl:col-span-2'>
            <div className='md:flex'>
              {data?.footer_links
                && data.footer_links.length > 0
                && data.footer_links.map((dt: any, i: number) => (
                  <div key={i} className={`${styles.footerBlock}`} style={{paddingRight: '2rem'}}>
                    <Link href={dt?.href} className={`${styles.subLinks}`}>
                      <h6 className={`${styles.heading}`}>
                        {dt.title}
                      </h6>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
          <div>
            <div className='mt-4 flex space-x-6 md:order-2'>
              {data?.social_links
              && data.social_links.length > 0
              && data.social_links.map((link: socialLink) => (
                link?.url ? <Link
                  key={link?.title || ''}
                  href={link.url}
                  className='text-gray-400 hover:text-gray-500'
                >
                  {link.title && <span className='sr-only'>
                    {link.title}
                  </span>}
                  {link?.icon?.url && (
                    <Image
                      contents={{
                        src: link.icon.url,
                        className: 'h-6 w-6',
                        ariaHidden: 'true'
                      }}
                    />
                  )}
                </Link> : <>
                  {link?.icon?.url && (
                    <Image
                      contents={{
                        src: link.icon.url,
                        className: 'h-6 w-6',
                        ariaHidden: 'true'
                      }}
                    />
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        <div className='mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between'>
          {data?.disclaimer && <div className={`text-white mt-8 md:mt-0 md:order-1 ${styles.copyrightText}`}>
            {ReactHtmlParser(data.disclaimer)}
          </div>}
        </div>
      </div>
    </footer>
  )
}

export default Footer
