import { FunctionComponent } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import 'react-lazy-load-image-component/src/effects/blur.css'

type propsType = {
  contents: {
    $?:any
    src: string
    alt?: string
    className?: string
    ariaHidden?: string
    maxWidth?: string
    noLazyLoad?: boolean
  }
}


const Image: FunctionComponent<propsType> = (props: propsType) => {
  const { contents } = props

  return (
    contents.noLazyLoad
      ? (
        <img src={contents.src} className = {contents.className} alt = {contents.alt} />
      ) : (
        
        <LazyLoadImage
          effect='blur'
          className = {`${contents.className} primaryImage w-full object-cover `}
          src = {contents.src.indexOf('?') > -1 ? `${contents.src}&auto=webp&format=pjpg` : `${contents.src}?width=${contents.maxWidth}&auto=webp&format=pjpg`}
          alt = {contents.alt}
          placeholderSrc = '/static/images/light-gray-background.jpg'
        />
      )
  )
}

export default Image