const encrypt = (plainText:any) => {
  let chiperText = ''
  const x = Math.floor((Math.random() * 9) + 1)
  for (let i = 0; i < plainText?.length; i++) {
    const existingCharCode = plainText.charCodeAt(i)
    const newCharCode = existingCharCode + x
    const newChars = String.fromCharCode(newCharCode)
    chiperText += newChars
  }
  return encodeURIComponent(`${x}${chiperText}`)
}

export const headers = {
  ENV: encrypt(process.env.REACT_APP_ENV)|| '',
  LIVE_PREVIEW_ENV: encrypt(process.env.REACT_APP_LIVE_PREVIEW_ENV)|| '',
  CS_API_KEY: encrypt( process.env.REACT_APP_CS_API_KEY) || '',
  CS_DELIVERY_TOKEN: encrypt(process.env.REACT_APP_CS_DELIVERY_TOKEN )|| '',
  CS_MANAGEMENT_TOKEN: encrypt(process.env.REACT_APP_CS_MANAGEMENT_TOKEN )|| '',
  CS_REGION: encrypt( process.env.REACT_APP_CS_REGION) || '',
  CS_HOST: encrypt( process.env.REACT_APP_CS_HOST) || '',
  CS_LP_HOST: encrypt(process.env.REACT_APP_CS_LP_HOST) || '',
  BC_STORE_HASH: encrypt(process.env.REACT_APP_BC_STORE_HASH )|| '',
  BC_X_AUTH_TOKEN: encrypt(process.env.REACT_APP_BC_X_AUTH_TOKEN) || '',
  CONSTRUCTOR_KEY: encrypt(process.env.REACT_APP_CONSTRUCTOR_KEY) || '',
  CONSTRUCTOR_AUTH: encrypt(process.env.REACT_APP_CONSTRUCTOR_AUTH) || ''
}

export const getURL = (slug : string): string => {
  const url=`${process.env.REACT_APP_URL || ''}${slug}`
  return url
}