import styles from './index.module.css'
import Image from '../Image'

type propsTypes = {
  contents: {
    cms_video?: {
      url: string
      title: string
    }
    dam_video?: string
  }
}

const Video:React.FC<propsTypes> = (props: propsTypes) => {
  const { contents } = props
  return (
    <div data-test='videoContainer'>
      {(contents?.cms_video || contents?.dam_video)  && (
        <div
          className='mx-auto max-w-7xl flex justify-center content-center 
          overflow-hidden  px-4 sm:px-6 lg:px-8  md:px-3 py-20'
          data-test='videoSection'
         
        >
          {(contents.cms_video?.title || contents?.dam_video) && (
            <video
              src={contents?.dam_video || contents?.cms_video?.url}
              title= {contents?.cms_video?.title || ''}
              className={ `shrink-0 ${styles.image} rounded-md`}
              muted autoPlay
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Video
