import React from 'react'
import AppRouter from './router/LocalizedRoutes'
import RecommandationProvider from './customhooks/recommandation'
import context from './context/uniformContext'
import { UniformContext } from '@uniformdev/context-react'

import { ErrorCtxProvider } from './context/ErrorContext'
import { LocaleCtxProvider } from './context/LocaleCtx'
import '@contentstack/live-preview-utils/dist/main.css'

type stackProps = {
  live_preview: {
    [key: string]: any
  }
  headers: {
    api_key: any
  },
  environment: any
}

export const stack: stackProps  = {
  live_preview: {clientUrlParams: { host: process.env.REACT_APP_CS_LP_CLIENTURLPARAMS_HOST }},
  headers: {api_key: process.env.REACT_APP_CS_API_KEY},
  environment: process.env.REACT_APP_ENV 
}
import ContentstackLivePreview from '@contentstack/live-preview-utils'

ContentstackLivePreview.init({enable: true, stackSdk: stack, ssr: false})

const App:React.FC = () => {
  return (
    <UniformContext context={context}>
      <LocaleCtxProvider>
        <ErrorCtxProvider>
          <RecommandationProvider>
            <div className='App'>
              <AppRouter />
            </div>
          </RecommandationProvider>
        </ErrorCtxProvider>
      </LocaleCtxProvider>
    </UniformContext>
  )
}

export default App
