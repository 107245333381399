// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ConstructorIOClient from '@constructor-io/constructorio-client-javascript'
import { useContext, createContext } from 'react'

const recomContext = createContext<any>({ value: {} })
const { Provider } = recomContext
const RecommandationProvider:React.FC  = (props:any)  =>{
  const  recommand = recommandation()
  return <Provider value={recommand}>{props.children}</Provider>
}
export default RecommandationProvider

export const useRecommandation = () => useContext(recomContext)

const constructorio = new ConstructorIOClient({ apiKey: 'key_pMYMUCnfQBErpw2Q'})


const recommandation = () => {

  const strategies = async ( prodId:string, numRes:number, bool:boolean, color: string) =>{
    try {
      const result = await constructorio.recommendations.getRecommendations(`${prodId}`, {numResults: numRes,...(bool) && { filters: {Color: `${color}`}}})      
      return result
    } catch (error) {
      console.error(`result failed: ${error}`)
    }
  }
  
  return {strategies}
}
