import React from 'react'
import style from './index.module.css'
import ReactHtmlParser from 'react-html-parser'
import  Link  from '../Link'
import PromoSection from '../PromoSection'
import Image from '../Image'

type propsType = {
  contents: {
    image_section?: {
      title?: string;
      url?: {
        url: string
      }[];
      promo_section?: boolean;
      abstract?: string;
      image?: {
        url: string;
        title: string;
      };
      dam_image?: {
        url: string;
        title: string;
      };
      commerce_data?: {
        url: string;
        title: string;
      };
    }[];

    title?: string;
    paragraph?: string;
    gridCol?: number;
    to?: string
  };
};


type productType={
  title:string,
  description:string,
  abstract:string,
  image:{
    url:string,
    title:string
  },
  dam_image:{
    url:string,
    title:string
  },
  commerce_data:{
    url:string,
    title:string
  },
  url:string,
  id:string,
}



const ImageCardMedium: React.FC<propsType> = (props: any) => {
  const { contents } = props
  return (
    <>
      {contents?.image_section && contents?.image_section?.length > 0
       && (

         <div className='bg-white container md:px-3'>
           <div className='py-8 sm:py-8 '>
             {contents?.title || contents?.paragraph
               ? (
                 <div className='w-full m-auto pb-8'>
                   {contents?.title && (
                     <div className='relative pb-7 flex justify-start items-center'>
                       <h3
                         className={`${style.h3} text-center font-bold text-3xl inline-block bg-white`}
                         aria-label='heading'
                       >
                         {contents.title}
                       </h3>
                       <div className='border-t border-grey-200 absolute left-0 right-0'></div>
                     </div>
                   )} 
                   {contents?.paragraph && (
                     <div className={`${style.paragraph} text-black font-normal text-base text-center w-[90%] md:w-3/4 lg:w-2/3 mx-auto leading-7`} aria-label='paragraph'>
                       {ReactHtmlParser(contents?.paragraph)}
                     </div>
                   )}
                 </div>
               ) : ''}
             <div
               className={
                 contents.gridCol
                   ? `flex items-center justify-center sm:justify-between flex-wrap lg:grid gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-${contents.gridCol} xl:gap-x-8`
                   : 'grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8'
               }>
               
               {contents.image_section.map((product:productType | any, i:any) => {
                 return (
                   <div data-id={product.id || ''} key={i} className={`p-4 group group-hover:opacity-75 mb-2 ${contents.gridCol?`${style.imageContainer}`:''}`}
                     style={{border: '0.5px solid #150a61'}}>
                     {/* {!product?.image?.url && product?.title && <h3 className='mt-4 text-lg text-gray-700 no-underline font-bold leading-4	'>
                       {product.title}
                     </h3>} */}
                     {product?.image?.url ? (
                       <div className='w-full h-250 border border-slate-100  rounded-lg overflow-hidden xl:aspect-h-8'>
                        
                         
                         {product.image?.url && (
                           <Image
                             contents={{
                               src: product.image.url,
                               alt: product.image.title,
                               className: `w-full object-center rounded-lg object-cover group-hover:opacity-75 ${style.opacity}`,
                               maxWidth: '300',
                               noLazyLoad: true
                             }}
                           />
                         )}
                        
                       </div>
                     ) :''
                     }

                     {product?.title && <h3 className='mt-4 text-lg text-gray-700 no-underline font-bold leading-4	'>
                       {product.title}
                     </h3>}
                     
                     {product?.paragraph && product.paragraph !== '<p></p>'
                       && <div className='mt-1 text-base leading-5 font-semibold text-gray-900 no-underline'>
                         {ReactHtmlParser(product.paragraph)}
                       </div>}
                     {product?.link?.url && <div className='flex flex-col items-end'><Link 
                       href={product?.link?.url}
                       className={`mt-2 btn ${product?.link?.theme === 'Theme-Primary' ? 'btnPrimary' : product?.link?.theme === 'Theme-PrimaryOutline' ? 'btnPrimaryOutline'
                         :product?.link?.theme === 'Theme-Secondary' ? 'btnSecondary': '' }`}
                     >
                       {product.link.title} {product?.link?.add_arrow ? <>&rarr;</>: ''}
                     </Link></div>}
                   </div>
                 )

               })}
             </div>
           </div>
         </div>
       )}
    </>
  )
}

export default ImageCardMedium
