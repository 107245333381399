import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import Image from '../Image'
import style from './index.module.css'
import Link from '../Link'


interface propsType  {
    title?: string
    subtitle?:string
    paragraph?: string
    background_image?: {
      title:string,
      url:string,
    }
    cta?: {
      title?: string
      url_field?: string
      url?: {
        url:string
      } []
    } | any
    uniform_app?: any
    intentTag?:any
} 

const HeroBanner: any = ({ $, title, paragraph, background_image, cta }:any) => {  
  return (
    <div className='hero-banner' data-test='bannerComponent'>
      <main>
        <div className='relative'>
          <div className=''>
            <div className='relative overflow-hidden max-w-7xl  mx-auto px-4  sm:px-6 lg:px-8   md:px-3 mt-24n' data-test='backgroundContainer'>
              {background_image?.url
                ? (
                  <div className={`absolute inset-0 ${style.overlay}` }
                    // {...background_image?.$?.url}
                  >
                    <Image
                      contents={{
                        src: background_image.url,
                        alt: background_image?.title,
                        className: 'h-full w-full object-cover opacity-70',
                        maxWidth: '2000'
                      }}
                    />
                    <div className='absolute  inset-x-0 top-0 bottom-0 bg-grey-400 mix-blend-multiply' />
                  </div>
                ) : <div className={`absolute inset-0   ${style.overlay, style.background}`} style={{minHeight: '300px'}}></div>}
              <div className={`relative px-4  sm:px-6 sm:py-24 lg:py-32 lg:px-8 ${window.location.pathname==='/'? 'py-2' :'py-24' }`} data-test='title'>
                {title
                  ? (
                    <h2 className='font-bold max-w-lg'>
                      <span className={`block text-10xl pt-10 ${background_image?.url ? 'text-white ': style.textBlue}`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        {...$?.title}
                      >{title}</span>
                    </h2>
                  ) : ''}
                {paragraph
                  ? (
                    <div className={`${style.paragraph} flex pt-4 justify-start `} data-test='paragraph'
                      {...$?.paragraph}
                    >
                      {ReactHtmlParser(paragraph)}
                    </div>

                  ) : ''}
                 
                <div className='flex pt-4 justify-start'>
                  {cta?.map((elem: { url: string; title: string; theme: string; add_arrow:'boolean'; $:any }) => (
                  
                    <Link 
                      {...elem?.$?.title}
                      href={elem?.url}
                      className={`btn ${elem?.theme === 'Theme-Primary' ? 'btnPrimary' : elem?.theme === 'Theme-PrimaryOutline' ? 'btnPrimaryOutline'
                        :elem?.theme === 'Theme-Secondary' ? 'btnSecondary': '' }`}
                    >
                      {elem.title} {elem?.add_arrow ? <>&rarr;</>: ''}
                    </Link>
                  ))}
                </div> 
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
export default HeroBanner
