import  { useEffect, useState } from 'react'
import { useNavigate , useLocation } from 'react-router-dom'
import { Popover} from '@headlessui/react'
import styles from './index.module.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { getURL } from '../../../utility/proxyConfig'
import { useLocaleCtx, defaultLocale ,getLanguageCode } from '../../../context/LocaleCtx'
import useFetch from '../../../customhooks/useFetch'

type navigationType = {
    links: []
    countries: []
    carousel: []
}

const Header:React.FC = () => {
  const {locale , updateLocale} = useLocaleCtx()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const [selectValue , setSelectValue] = useState<string>('')
  const [header, setHeader] = useState<navigationType>({
    links: [],
    countries: [],
    carousel: []
  })

  const {data} = useFetch(getURL(`/content/header?locale=${getLanguageCode(locale)}`))

  useEffect(() => {
    setSelectValue(locale)
    if (data) {
      setHeader({
        ...header,
        links: data.links,
        countries: data.countries
        // carousel: data.carousel
      })
    }
  }, [data,locale])  
 

  const isLocale = (loc:string) => {
    return header.countries.find((x : {lang_code: string} ) => x.lang_code === loc)
  }
  const handleLocale = (event : React.ChangeEvent<HTMLSelectElement>) => {
    setSelectValue(event.target.value)
    updateLocale(event.target.value)
    
    const pathArr = pathname.split('/').filter((elem) => {
      if(!isLocale(elem)) {
        return elem
      }
    }).join('/').concat(search)
    if (event.target.value === defaultLocale) 
      navigate(`/${pathArr}`)
    else 
      navigate(`/${event.target.value}/${pathArr}`)
  }

  return (
    <Popover className='relative mx-auto sm:px-6 lg:px-0'>
      <div className={`bg-blue text-white flex justify-center items-center md:justify-start md:space-x-10 ${styles.headerHeight}`}>
        <div className='flex items-center max-w-7xl justify-end md:flex-1 lg:w-0'>
          {header?.countries
            && header.countries.length > 0
            && <select className={styles.select} value = {selectValue} onChange={handleLocale}>
              {header.countries.map((region : {lang_code: string, link: {title: string}}) => (
                <option value={region.lang_code}
                  key={region.lang_code}>
                  {region.link.title}
                </option>
              ))}
            </select> }
          {header?.links
            && header.links.length > 0
            && header.links.map((link: {url: string, title: string, open_in_new_tab: boolean},index:number) => (
              link?.url && <div  key={index} className='cta-section mr-8'>
                <a
                  
                  href={link.url}
                  target={link?.open_in_new_tab ? '_blank':'_self'}
                  className={`whitespace-nowrap ${styles.ctaSection}`}
                  rel='noreferrer'
                >
                  {link.title}
                </a>
              </div>
            ))}
          
        </div>
      </div>
    </Popover>
  )
}

export default Header
