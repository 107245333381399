import style from './index.module.css'
import ReactHtmlParser from 'react-html-parser'
import Image from '../Image'

type propsType = {
  contents: {
    dark_mode?: boolean;
    title: string;
    top_heading: string;
    video_url?: string;//change the type
    paragraph: string;
    image?: {
      url: string;
      title: string;
    };
    classStyle?: string;
    gridStyle?: string;
    imageStyle?: string;
    textAlign?: string;
  };
};

const TextAsset:React.FC<propsType> = (props: propsType) => {
  const { contents } = props

  return (
    <div className={`relative overflow-hidden mt-24 mx-auto ${contents?.dark_mode? '' : 'px-4 max-w-7xl sm:px-6 lg:px-8   md:px-3'} `} >
      {contents && contents?.image?.url&& (
        <div className='relative pt-4 ' data-test='textassetContainer'>
          <main style={{backgroundColor: contents?.dark_mode ? 'gray': 'white'}}>
            <div className={`${contents.textAlign === 'center' ? '' : contents?.video_url || contents?.image?.url ? 'lg:grid lg:grid-cols-12 lg:gap-8 ' : 'center'}`}>
              <div className={`px-4 py-16 sm:text-center md:mx-auto ${contents.gridStyle === 'col-9' ? 'lg:col-span-4' : contents.gridStyle === 'col-6' ? 'lg:col-span-6' : 'lg:col-span-6'}
               ${contents.textAlign === 'center' ? 'lg:text-center' : contents?.video_url || contents?.image?.url ? 'lg:text-left' : 'lg:text-center'} ${contents.textAlign === 'right' ? 'order-2' : ''}`}
              data-test='contentContainer'>
                {contents?.title && <h6 data-test='title'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  {...contents?.$?.title}
                  className={` ${contents?.dark_mode ?'text-white': 'text-black'}`}
                >{contents.title}</h6>}
                {contents?.top_heading && (
                  <h2
                    className={`tracking-wide ${style.topHeading} ${contents?.dark_mode ?'text-white': 'text-black'}`}
                    data-test='topHeading'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    {...contents?.$?.subtitle}
                  >
                    {contents.top_heading}
                  </h2>
                )}
                {contents?.paragraph && (
                  <div className={`mt-3 ${style.paragraph} ${contents?.dark_mode ? style.darkMode : ''}`} data-test='paragraph' 
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                    {...contents?.$?.paragraph}>
                    {ReactHtmlParser(contents.paragraph)}
                  </div>
                )}
              </div>
              <div className={`mt-2 relative p-8 sm:max-w-lg sm:mx-auto lg:max-w-none lg:mx-0 ${contents.gridStyle === 'col-9' 
                ? 'lg:col-span-8' : contents.gridStyle === 'col-6' ? 'lg:col-span-6' : 'lg:col-span-6'}  ${contents.textAlign === 'center' ? 'lg:justify-self-center' : contents.textAlign === 'left' ? 'lg:justify-self-end lg:mt-0' : 'lg:mt-0'}`} >
                {contents?.image && contents?.image?.title && contents?.image?.url? (
                  <div className={`rounded-lg shadow-lg ${contents.textAlign === 'center' ? '' : 'lg:max-w'} ${contents.imageStyle} ${style.imageStyle} `} 
                    data-test='imageContainer'
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    {...contents?.image.$?.url}
                  >
                    <Image
                      contents={{
                        src: contents.image.url,
                        alt: contents.image.title,
                        className: `w-full rounded-lg ${contents.classStyle}`,
                        maxWidth: '1000'
                      }}
                    />
                  </div>
                ) : (
                 
                  contents?.video_url && (
                    <div className={`rounded-lg shadow-lg  ${style.imageStyle} `} data-test='videoContainer'>
                      <video controls 
                      
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        {...contents?.$?.video_url}>
                        <source
                          src={contents.video_url}
                          type='video/mp4'
                        />
                      </video>
                    </div>
                  )
                 
                )}
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  )
}

export default TextAsset
