import Styles from './index.module.css'
import ReactHtmlParser from 'react-html-parser'

type propsType = {
  contents: {
    title?: string
    paragraph?: string,
    cta?: {
      title?: string
      url?: {
        url: string
      }[]
    }
    backgroundColor?: boolean
    removeMargin?: boolean
  }
}

const TextCta:React.FC<propsType> = (props: propsType) => {
  const { contents } = props
  return (
    <div
      className={`${ contents.backgroundColor ? `${Styles.darkMode} ${Styles.textCta}` : `${Styles.textCta} ${Styles.lightMode}` }`}
      data-test='textCtaContainer'
    >
      <div className={`${Styles.bgColor}`} data-test='contentContainer'>
        <div className={`max-w-2xl mx-auto text-center ${!contents.removeMargin ? 'py-16 px-4 sm:py-20 sm:px-6 lg:px-8' : ''}`} data-test='removeMarginClass'>
          {contents.title ? (
            <h2 className='text-2xl lg:text-3xl font-extrabold sm:text-4xl'
              data-test='title'
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              {...contents?.$?.title}>
              <span>{contents.title}</span>
            </h2>
          ) : (
            ''
          )}
          {contents?.paragraph
            && (
              <div
                className='py-2 leading-6 text-lg'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                {...contents?.$?.paragraph}
                data-test='paragraph'
              >
                {ReactHtmlParser(contents.paragraph)}
              </div>
            )}

          {contents?.cta?.url?.length && contents.cta.title ? (
            <div className={`mt-8 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center ${Styles.button}`} data-test='cta'>
              <a
                href={contents.cta.url[0].url}
                className='btn btnSecondary'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                {...contents?.cta.$?.title}
              >
                {contents.cta.title}
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}
export default TextCta
