import React, { Fragment, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Error from '../pages/Error'
import Header from '../component/common/Header'
import Navigation from '../component/common/Navigation'
import Footer from '../component/common/Footer'
// import  routes  from './appRoutes'
import { generateUniqueKey, getSlugFromPath } from '../utility'
import Home from '../pages/home'
import { getURL, headers } from '../utility/proxyConfig'
import axios from 'axios'
import Category from '../pages/category/index'
import Landing from '../pages/landing/index'
import ProductDetail from '../pages/product-detail'
import BlogOverview from '../pages/blogOverview'
// import Page from '../pages'
import BlogDetail from '../pages/blogDetail/index'
import Search from '../pages/search'
import { stack } from '../App'

const AppRouter: React.FC = () => {
  // const [appRoutes, setAppRoutes] = useState([])
  const location = useLocation()

  // const matchedRoutes = appRoutes.map((route: { url: string }) => {
  //   if(route.url === `/${getSlugFromPath(location.pathname)}`) return route
  // }).filter((x) => x)

  // const getRoutes = async () =>  {
  //   const response = await axios.get(getURL('/content/getRoutes'), {headers})
  //   if(response?.data) {
  //     setAppRoutes(response.data)
  //   }
  // }

  // const Element:any = {
  //   landing_page: <Home/> ,
  //   product_landing_page: <Category/> ,
  //   product_detail_page: <ProductDetail/>,
  //   blog_landing_page: <BlogOverview/>,
  //   blog_detail_page: <BlogDetail/>,
  //   landing_pages: <Landing/> 
  // }

  // useEffect(()=>{
  //   getRoutes()
  // },[])

  return (
    <div>
      <Header/>
      <Navigation/>
      <Routes> 
        <Route 
          path='/' 
          element={<Home />} />
        <Route 
          path={'/fr'} 
          element={<Home />} />
        <Route 
          path={'/de'} 
          element={<Home />} />

        <Route
          path={'/search'} 
          element={<Search />} />

        <Route
          path={':lang/search'} 
          element={<Search />} />

        {/* {appRoutes?.length > 0 && appRoutes.map((route) => {
          return route.url !== '/'  && <Fragment key={generateUniqueKey()}>
            <Route path={route.url} element={Element[route.content_type]} />
            <Route path={`:lang${route.url}`} element={Element[route.content_type]} />            
          </Fragment>
        })} */}

        {/* {appRoutes?.length > 0 && <>
          <Route
            path={'/p/*'}
            element={<ProductDetail />} />
          <Route
            path={':lang/p/*'}
            element={<ProductDetail />} />
        </>} */}

        {/* <Route 
          path='*' 
          element={stack?.live_preview?.content_type_uid === 'landing_page' ? <Home/> 
            : stack?.live_preview?.content_type_uid === 'landing_pages' ? <Landing /> 
              : stack?.live_preview?.content_type_uid === 'product_landing_page' ? <Category /> 
                : stack?.live_preview?.content_type_uid === 'product_detail_page' ? <ProductDetail />
                  : stack?.live_preview?.content_type_uid === 'blog_landing_page' ? <BlogOverview/>
                    : stack?.live_preview?.content_type_uid === 'blog_detail_page' ? <BlogDetail/>
                      : appRoutes.length > 0 && matchedRoutes.length <= 0 && location.pathname.includes('/p/') ? <ProductDetail /> 
                        : appRoutes.length > 0 && matchedRoutes.length <= 0 && !location.pathname.includes('/p/') && <Error />
          }/> */}
      </Routes>
      <Footer/>
    </div>
  )
}
export default AppRouter
