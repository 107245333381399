import React , {useEffect, useState}from 'react'
import { useRecommandation } from '../../../customhooks/recommandation'
import styles from './index.module.css'
import Image from '../Image'
import Link from '../Link'
import * as Utility from '../../../utility'


type propsType={
  contents:{
    title:string,
    cta?: {
      title:string,
      url:string | {
        url: string
      }[]
    }
  }
}

type recomdedProps={
  id:string|number,
  data:{
    url:string,
    image_url:string,
    price:string
  },
  value:string,
}


const index:React.FC<propsType> = (props:propsType) => {
  const recommand = useRecommandation()
  const { sameHeight } = Utility
  const [recommandedData, setRecommandedData] = useState([])
  useEffect(()=>{
    (async () => {
      try{
        const res = await recommand.strategies('item_page_3',5,true,'Red')
        setRecommandedData(res.response.results)
      }catch(err){
        ''
      }
    })()
  },[])

  const {contents}=props
  useEffect(() => {
    const headingHeight = document.querySelectorAll('.heading')
    const sameHeightFn = () => {
      sameHeight(headingHeight, 767)
    }
    sameHeightFn()
    window.addEventListener('resize', () => {
      sameHeightFn()
    })

    return () => {
      window.removeEventListener('resize', sameHeightFn)
    }
  })

  return (
    <div>
      {recommandedData
        ?<div className='max-w-2xl mx-auto py-16 px-4 sm:pt-14 sm:pb-6 sm:px-6 lg:max-w-7xl lg:px-8 '>
          <div className='md:flex md:items-center md:justify-between '>
            {contents?.title && recommandedData?.length > 0 && <h2 className='text-2xl font-bold  text-black leading-7'>{contents.title}</h2>}
            {contents?.cta?.title && contents?.cta?.url && <Link 
              href = {contents.cta.url}
              className='text-sm font-semibold text-indigo-600 hover:text-indigo-500 no-underline mt-4 sm:mt-0'
            >
              {contents.cta.title}
              <span aria-hidden='true'> &rarr;</span>
            </Link> }
          </div>
          <div className={`mt-7 mb-1 grid md:grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-5 md:gap-y-0 lg:gap-x-6 ${styles.gridView}`}>
            {recommandedData?.length > 0 && recommandedData.map((product: recomdedProps) => (
              <div key={product.id} className='group relative mt-6 mx-auto flex flex-col'>
                <div className='w-[221px] h-[251px] bg-gray-200 rounded-lg overflow-hidden group-hover:opacity-75'>
                  <Image
                    contents={{
                      src: product?.data?.image_url,
                      // alt: product.imageAlt,
                      className: 'w-full h-auto object-center object-cover'
                    }}
                  />
                </div>
                <h3 className='mt-5 mb-1 heading  font-bold text-2xl leading-4'>
                  <Link href={`/p${product?.data?.url}`} className={`${styles.productName} text-black no-underline font-normal`}>
                    <span className='absolute inset-0 ' />
                    {product.value}
                  </Link>
                </h3>
                <p className='mt-1 text-base leading-5 font-semibold text-black'>{`$${product.data.price}`}</p>
              </div>
            ))}
          </div>
          {/* {contents?.cta?.title&&contents?.cta?.url
          && <div className='mt-8  md:hidden'>
            <Link href={contents.cta.url} className='font-semibold no-underline'>
              {contents.cta.title}<span aria-hidden='true'> &rarr;</span>
            </Link>
          </div>} */}
        </div>
        : ''}

    </div>
  )
}

export default index
