import { getLanguageCode } from '../context/LocaleCtx'

export const sameHeight = (data:any, windowWidth:number):void => {
  const heightArray:number[] = []
  let maxHeight: number
  if (window.innerWidth > windowWidth) {
    data.length > 0 && data.forEach((card:HTMLElement) => {
      card.style.flex = '0'
      heightArray.push(card.offsetHeight)
    })
    maxHeight = Math.max(...heightArray)
    data.length > 0 && data.forEach((card:HTMLElement) => {
      card.style.flex = `0 0 ${maxHeight}px`
    })
  } else {
    data.length > 0 && data.forEach((card:HTMLElement) => {
      card.removeAttribute('style')
    })
  }
}

export const reshapeVariations = (variants:any) => {
  if (!variants) return
  // let i = 0
  return variants?.map((bannerVar:any) => {
    const { context_personalization_criteria, ...fixed } = bannerVar
    fixed.pz = context_personalization_criteria?.name
    // eslint-disable-next-line no-plusplus
    // fixed.id = i
    return fixed
  })
}
  
export const generateUniqueKey = ():string => {
  return `key-${Math.random().toString(36).substr(2, 16) + new Date().getUTCMilliseconds()}`
}

export const getSlugFromPath = (path:string) => {
  return path.split('/').filter((elem) => {
    if(!getLanguageCode(elem)) {
      return elem
    }
  }).join('/')
}

export const getLocaleFromPath = (path : string):string => {
  const pathArr = path.split('/')
  for(let i=0; i < pathArr.length && i<=2; i++) {
    if(getLanguageCode(pathArr[i])) {
      return pathArr[i]
    }
  }
  return 'en'
}

