import React from 'react'
import Image from '../Image'
import styles from './index.module.css'
import Link from '../Link'


interface propsType  {
    title?: string
    image?: {
      title:string,
      url:string,
    }
    banner_url?: {
      url?:string
    }[]
    uniform?: any
    intentTag?:any
} 

const MenuBanner:any = ({  title, image, banner_url, uniform }:any) => {

  return (
    <div className='menu-banners'>
      <Link 
        href={banner_url && banner_url?.length > 0 && banner_url[0].url}
        className={styles.textDecoration}
      >
        {image?.url && (
          <Image
            contents={{
              src: image.url,
              alt: image.title,
              className: styles.menuImage
            }}
          />
        )}
        <p>{title}</p>
      </Link>
    </div>
  )
}
export default MenuBanner
