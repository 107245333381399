
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ConstructorIOClient from '@constructor-io/constructorio-client-javascript'
import { FunctionComponent, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import useFetch from '../customhooks/useFetch'
import { useErrorCtx } from '../context/ErrorContext'
import { getURL } from '../utility/proxyConfig'
import ImageCardM from '../component/common/ImageCardMedium'
import TextMultiImageDark from '../component/common/TextMultiImageDark'
import Error from './Error'
import { defaultLocale, getLanguageCode, useLocaleCtx } from '../context/LocaleCtx'
import RecommendationSection from '../component/common/Recommendationsection'
import Seo from '../seo'
interface responseType {
  data:{
    description: string,
    id: string,
    image_url: string,
    price: number,
    url: string
  },
  result_id: string,
  value: string,
  matched_terms: [string]
}

let response: responseType[] = []
const Search: FunctionComponent = () => {
  const {data, error, loading} = useFetch(getURL('/content/search?refUids=promo_section.cta.url'))
  const { locale, updateLocale } = useLocaleCtx()
  const { lang } = useParams()
  const {updateErr} = useErrorCtx()
  const location = useLocation() as any
  const [currentItems, setCurrentItems] = useState<any[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const searchResult: any[] = []
  let itemsLength = 0
  let queryString

  const constructorio = new ConstructorIOClient({ apiKey: process.env.REACT_APP_CONSTRUCTOR_KEY })
  const { search } = useLocation()
  const name = new URLSearchParams(search).get('q')
  
  if (name) {
    queryString = name.replace(/-/g, ' ')
    const str = queryString?.toLowerCase().split(' ')
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1) 
    }
    queryString = str.join(' ')
  }
  
  const constructorSearch = async (query: string| undefined) => {
    try {
      const result = await constructorio.search.getSearchResults(query ||'')
      return result.response.results
    } catch (errorr) {
      // eslint-disable-next-line no-console
    }
  }
  
  constructorSearch(queryString).then(result => {
    response = result
  })

  let searchContent: any[] = []
  if (location?.state?.length) {
    searchContent = location.state
  } else {
    searchContent = response
  }

  if (searchContent?.length) {
    itemsLength = searchContent.length
    for(let i = 0; i < searchContent.length; i++) {
      const productObj = {
        'id': searchContent[i].data.id,
        'title': searchContent[i].value,
        'url': searchContent[i].data?.url,
        'abstract': searchContent[i].data.price,
        'image': {
          'url': searchContent[i].data.image_url,
          'title': searchContent[i].value
        },
        'description': searchContent[i].data.description
      }
      searchResult.push(productObj)
    }
  }

  useEffect(()=>{
    if(lang && getLanguageCode(lang)) updateLocale(lang)
    else updateLocale(defaultLocale)
  },[locale])

  useEffect(() => {
    setCurrentItems(searchResult)
  }, [JSON.stringify(searchResult)])

  useEffect(() => {
    const endOffset = itemOffset + 12
    setCurrentItems(searchResult.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(searchResult.length / 12))
  }, [itemOffset, 12, JSON.stringify(searchResult)])

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * 12) % searchResult.length
    setItemOffset(newOffset)

    const productListWrapper = document.querySelector('.imageCardMedium')
    let productListOffset
    if (productListWrapper instanceof HTMLElement) {
      productListOffset = productListWrapper?.offsetTop
    }

    window.scrollTo({
      top: productListOffset,
      behavior: 'smooth'
    })
  }

  const prevArrow = <span className='flex'><ArrowNarrowLeftIcon className='mr-3 h-5 w-5 text-gray-400' aria-hidden='true' />Previous</span>
  const nextArrow = <span className='flex'>Next<ArrowNarrowRightIcon className='ml-3 h-5 w-5 text-gray-400' aria-hidden='true' /></span>

  return (
    <>
      {data && loading
        && (
          <>
            <Seo
              contents = {{
                title: data?.seo_and_search?.title,
                description: data?.seo_and_search?.description,
                canonical_url: data?.seo_and_search?.canonical_url,
                og_meta_tags: data?.seo_and_search?.og_meta_tags,
                no_follow: data?.seo_and_search?.no_follow,
                no_index: data?.seo_and_search?.no_index,
                show_in_site_search: data?.seo_and_search?.show_in_site_search,
                show_in_sitemap: data?.seo_and_search?.show_in_sitemap
              }}
            />
            <div>
              {currentItems?.length > 0
                ? (
                  <div>
                    <div className='container m-12 pb-5 flex items-center border-b border-gray-200 flex-wrap'>
                      <h4 className='mr-5'>{queryString}</h4>
                      <p className='text-base leading-7 font-medium'>{itemsLength} items</p>
                    </div>
                    <div className='imageCardMedium'>
                      <ImageCardM
                        contents={{
                          to: '/p',
                          image_section: currentItems.length
                            ? currentItems
                            : []
                        }}
                      />
                    </div>
                    {searchResult.length > 12
                      ? (
                        <div className='container pt-12 text-center'>
                          <ReactPaginate
                            breakLabel='...'
                            nextLabel={nextArrow}
                            onPageChange={(e) => handlePageClick(e)}
                            pageCount={pageCount}
                            previousLabel={prevArrow}
                            pageClassName='pageItem'
                            pageLinkClassName='pageLink'
                            previousClassName='previousArrow'
                            nextClassName='nextArrow'
                            containerClassName='pagination'
                            activeClassName='activePage'
                          />
                        </div>
                      ) : ''}
                  </div>
                ):(
                  <div className='container pt-12 text-center'>
                    <h6>No Result Found</h6>
                  </div>
                )}
      
              {data?.promo_section?.images?.length > 0
              && (
                <TextMultiImageDark
                  contents={{
                    title: data.promo_section.title,
                    cta: data.promo_section?.cta?.title
                      ? data.promo_section.cta
                      : {},
                    images: data.promo_section.images
                  }}
                />
              )}
              <RecommendationSection
                contents={{
                  title: 'Recently Viewed'
                // cta: {
                //   title: 'Browse all',
                //   url: '#'
                // }
                }}/>
            </div>
          </>
        )}
      {!data && !loading
        && (
          <>
            {error && updateErr(error)}
            <Error />
          </>
        )}
    </>
  )
}

export default Search