import { Personalize } from '@uniformdev/context-react'
import MenuBanner from '../MenuBanner'
import useFetch from '../../../customhooks/useFetch'
import { getURL } from '../../../utility/proxyConfig'
import {ChevronDoubleRightIcon } from '@heroicons/react/outline'
import styles from './index.module.css'
import Image from '../Image'
import Link from '../Link'
import {reshapeVariations} from '../../../utility'


type searchType={
 name:string
}[] 

// type productType={
//   'Search Suggestions':{
//     href:string,
//   }[]
//   Products:{
//     data:{
//       description: string,
//       image_url: string,
//       price: string | number,
//       url:string
//     }
//   }[]
  

// }
const SearchBox = (props: any) => {
  const { Products } = props
  const refFields = 'banner,banner.banner_url'
  const { data} = useFetch(getURL(`/content/search?refUids=${refFields}`))
  
  const searchData:searchType = [
    {name: 'jean pant'},
    {name: 'jean skirt'},
    {name: 'jean shirt'},
    {name: 'jean shirt'}
  ]
  
  // let variations
  // if(data?.banner && data?.banner?.length > 1) { 
  //   const mappedData = data?.banner?.map((bann:typeof data.hero_banner[0]) => {
  //     delete bann?.uniform?.intents?.$
  //     delete bann?.uniform?.name?.$
  //     delete bann?.uniform?.name?.intents?.$
  //     return {
  //       ...bann,
  //       intentTag: {intents: bann?.uniform?.name?.intents || null}
  //     }
  //   })
  //   variations = mappedData
  // }

  return(
    <div id='search-id' className='searchbox'>
      <div className='relative bg-white'>
        <div className='absolute top-1/2 inset-x-0 text-gray-500 sm:text-sm'>
          <div className='relative bg-white z-20 shadow-lg'>
            <div className='max-w-7xl mx-auto px-6 lg:px-8'>
              <div className='grid grid-cols-1 items-start gap-y-10 gap-x-8 py-6 sm:py-10 md:grid-cols-2 lg:gap-x-10'>
                <div className='grid grid-cols-2 border-b sm:border-b-0 sm:border-r border-gray-300 pb-8 sm:pr-8 h-full w-full'>
                  {data?.banner?.length > 1
                    ?  <Personalize variations={reshapeVariations(data?.banner)} component={MenuBanner} name='menu-personalization'/>
                    :  data?.banner?.length === 1 && <MenuBanner {...data?.banner[0]} />
                  }
                  <div className='recent-search-section pl-6'>
                    <ul>
                      {props['Search Suggestions']?.length
                        ? (
                          props['Search Suggestions'].map((dt: any,i: number) => (
                            <li key={i} className='py-3 text-sm leading-6 font-light text-black flex items-center justify-between relative'>
                              <span>
                                {dt.value}
                              </span>
                              <span>
                                <ChevronDoubleRightIcon className={styles.icon}/>
                                <Link href={`/search/?q=${(dt.data.id).replace(/\s+/g,'-')}`}>
                                  <span className='absolute inset-0' />
                                </Link>
                              </span>
                            </li>
                          ))
                        )
                        : (
                          searchData.map((dt,i: number) => (
                            <li key={i} className='py-3 text-sm leading-6 font-light text-black flex items-center justify-between relative'>
                              <span>
                                {dt.name}
                              </span>
                              <span>
                                <ChevronDoubleRightIcon className={styles.icon}/>
                              </span>
                            </li>
                          ))
                        )
                      }
                    </ul>
                  </div>
                </div>
                {Products?.length
                  ? (
                    <div className='space-y-4 sm:pl-6'>
                      <p className='text-lg font-bold text-black'>Top search results</p>
                      {Products.slice(0, 3).map((prod: any) => {
                        return (
                          <div className='flex items-center relative' key={prod.data.id}>
                            {prod.data?.image_url
                              ? (
                                <div> 
                                  <Image
                                    contents = {{
                                      className: 'inline-block h-24 w-24 rounded-md border border-grey-200 object-cover',
                                      src: prod.data.image_url,
                                      alt: prod.value
                                    }}
                                  />
                                </div>
                              ) : ''}
                            <div className='ml-7'>
                              {prod.value
                                && (
                                  <p className='text-xs'>
                                    {prod.value}
                                  </p>
                                )}
                              {prod.data.price
                                && (
                                  <p className='font-semibold'>
                                    ${prod.data.price}
                                  </p>
                                )}
                            </div>
                            <Link href={`/p${prod.data.url}`}>
                              <span className='absolute inset-0' />
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    data?.sponsers?.image?.url && <div className='sm:pl-6 sponsor-section'>
                      <Link href={data.sponsers.url}>
                        <Image
                          contents={{
                            src: data.sponsers.image.url,
                            alt: data.sponsers.image.title,
                            className: styles.sponsorImage
                          }}
                        />
                      </Link>
                    </div>
                      
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchBox