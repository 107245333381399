import { useEffect } from 'react'
import {Helmet} from 'react-helmet'
import { useLocaleCtx } from './context/LocaleCtx'


type propsType = {
  contents: {
    title:string
    description:string
    canonical_url:string,
    og_meta_tags:{
      description: string
      image: {url:string}
      title: string
    }
    no_follow:string ,
    no_index: string
    show_in_site_search: string
    show_in_sitemap: string
  }
}


const SEO:React.FC<propsType> = (props: propsType) => {
  const { contents } = props
  const {locale} = useLocaleCtx()
  let robots
  if (contents?.no_follow&&contents?.no_index) {
    robots = 'noindex,nofollow'
  } else if (contents?.no_follow) {
    robots = 'noindex'
  } else if (contents?.no_index) {
    robots = 'nofollow'
  } else {
    robots = 'index,follow'
  }

  let siteRobots
  if(contents?.show_in_site_search&&contents?.show_in_sitemap){
    siteRobots = 'showinsitesearch,showinsitemap'
  }else if(contents?.show_in_site_search){
    siteRobots='showinsitesearch'
  }else if(contents?.show_in_sitemap){
    siteRobots='showinsitemap'
  }
  
  useEffect(()=>{
    const link = document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = '/static/images/MantaShape-Avatar2.png'
    const  head = document.head || document.getElementsByTagName('head')[0]
    head.appendChild(link)
  },[])

  return (
    <Helmet>‍
      <title>{contents?.title}</title>‍
      <meta name='description' content={contents?.description} />       
      <meta property='og:title' content={contents?.og_meta_tags?.title} />       
      <meta property='og:description' content={contents?.og_meta_tags?.description}/>      
      <meta property='og:image' content={contents?.og_meta_tags?.image?.url}/>
      <meta property='og:locale' content={locale} />
      <meta name='robots' content={robots} key='robots' />
      <meta name='robots' content={siteRobots} key='robots' />
      <link rel='canonical' href={contents?.canonical_url}/>
      
     
      {/* <meta property='og:site_name' content='Pets - Products' />
     
      <meta property='og:type' content='article' /> */}
     
    </Helmet>
  )
}
export default SEO