
import { useErrorCtx } from '../context/ErrorContext'
import Styles from '../pages/product-detail/product-detail.module.css'


const Error: React.FC = () => {
  const {Err} = useErrorCtx()
  return(
    <div className={`${Styles.greybackgroundGradient}`}>
      {Err
        ? <div className='min-h-full pt-24 pb-24 flex flex-col '>
          <main className='flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='py-16'>
              <div className='text-center'>
                <h3 className='mt-2 text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'> {Err}</h3>
              </div>
            </div>
          </main>
        </div>
        : <>
          <div className='min-h-full pt-24 pb-22 flex flex-col '>
            <main className='flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-sm font-semibold text-indigo-600 uppercase tracking-wide'>404 error</p>
                  <h1 className='mt-2 text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>Page not found.</h1>
                  <p className='mt-2 text-base text-gray-500'>Sorry, we couldn’t find the page you’re looking for.</p>
                  <div className='mt-6'>
                    <a href='/' className='text-base font-medium text-indigo-600 hover:text-indigo-500'>Go back home<span aria-hidden='true'> &rarr;</span></a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </>}
    </div>
  )
}

export default Error