// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import ConstructorIOClient from '@constructor-io/constructorio-client-javascript'
import React, { useEffect, useState ,MouseEvent} from 'react'
import useFetch from '../../../customhooks/useFetch'
import { getURL } from '../../../utility/proxyConfig'
import classNames from 'classnames'
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { SearchIcon, ShoppingBagIcon } from '@heroicons/react/outline'
import { getLanguageCode, useLocaleCtx } from '../../../context/LocaleCtx'
import styles from './index.module.css'
import SearchBox from '../SearchBox'
import Image from '../Image'
import Link from '../Link'
import {useNavigate } from 'react-router-dom'

import Shimmering from '../Shimmer/shimmerImage'

type navType={
  logo:{
    title:string,
    url:string,
  }
  menus:{
    title: string,
  url:{
    url:string
  }[]
  sub_menu:{
    ind:string,
    title:string,
    url:{
      url:string,
    }[]
    group:{
      title:string,
      link:{
        title:string,
        external_url:string,
      }[]
    }[]
  }[]
  }[]
  
}

type itemProps={
  ind:number,
  title:string,
 
}

const Navigation: React.FC = () => {
  
  const { locale } = useLocaleCtx()

  // const refFields = 'menus.sub_menu.banner ,menus.sub_menu.banner.banner_url, menus.sub_menu.url ,menus.sub_menu.group.url,menus..group.link.internal_url,menus.url'
  const refFields=''
  const { data } = useFetch(getURL(`/content/navigation?locale=${getLanguageCode(locale)}&refUids=${refFields}`))

  const [navData, setNavData] = useState<navType>(data)
  const [searchBox, setSearchBox] = useState(false)
  const [isShow,setIshow]=useState(0)
  const [searchData, setSearchData] = useState<any>([])
  const [autocompleteData, setAutocompleteData] = useState<any>([])
  const [isShowPanel,setisShowPanel]=useState('')

  const navigate = useNavigate()

  // const constructorio = new ConstructorIOClient({ apiKey: process.env.REACT_APP_CONSTRUCTOR_KEY})
  
  // const constructorSearch = async (query:string) => {
  //   try {
  //     const result = await constructorio.search.getSearchResults(query ||'')
  //     return result.response.results
  //   } catch (error) {
  //     // console.log('🚀 ~ constructorSearch ~ error', error) 
  //   }
  // }

  // const constructorAutocomplete = async (query:string) =>{
  //   try {
  //     const result = await constructorio.autocomplete.getAutocompleteResults(query||'', {
  //       resultsPerSection: {
  //         Products: 5,
  //         'Search Suggestions': 5
  //       },
  //       numResults: 20,
  //       filters: undefined,
  //       hiddenFields: [],
  //       variationsMap: undefined
  //     })
  //     return result.sections
  //   } catch (error) {
  //     // console.error(`autocomplete faild to load : ${error}`)
  //   }
  // }

  // const handleKeyPress = (e:React.KeyboardEvent)  => {
  //   const query = (e.target as HTMLInputElement).value.toLowerCase()
  //   if (e.key === 'Enter') {
  //     navigate( locale === 'en' ? `/search/?q=${query.replace(/\s+/g,'-')}` : `${locale}/search/?q=${query.replace(/\s+/g,'-')}`, {state: searchData})
  //     const searchId = document.querySelectorAll('#search-id')
  //     if (searchId && searchId[0] instanceof HTMLElement) {
  //       searchId[0].style.display = 'none'
  //     }
  //   }
  // }

  // const filter = async (event:React.ChangeEvent) => {
  //   const value =(event.target as HTMLInputElement).value.toLowerCase()
  //   const response = await constructorSearch(value)
  //   const autocompleteRes = await constructorAutocomplete(value)
  //   setSearchData(response)
  //   setAutocompleteData(autocompleteRes)
  // }

  useEffect(() => {
    if (data) {
      setNavData(data)
    }
  }, [data,locale])  

  const onClick = (e: MouseEvent ) => {
    e.preventDefault()
    const id = (e.target as HTMLElement).id
    setIshow(parseInt(id, 10))
    const test = document.querySelectorAll('.test') 
    test?.forEach((el: any) => {
      if (el.id === id) {
        el.style.display = 'flex'
      } else {
        el.style.display = 'none'
      }
    })
  }
  const navButton = (e: MouseEvent) => {
    const title=(e.target as HTMLElement).getAttribute('data-title')
    if(title!==null){
      setisShowPanel(title)
    }
  }

  const handleClose=()=>{
    setisShowPanel('')
  }

  useEffect(() => {
    const panelDisplay = (e:Event) => {
      const navMenu = document.getElementById('navMenu')
      const searchPanel = document.getElementById('searchPanel')
      if ((e.target as HTMLElement).classList.contains('search-box')) {    
        if(!searchBox) {
          setSearchBox(true)
        }
      } else if ((searchPanel?.contains((e.target as HTMLElement))) && !(navMenu?.contains((e.target as HTMLElement)))) {
        if(!searchBox) {
          setSearchBox(true)
        }
      } else {
        if(searchBox) {
          setSearchBox(false)
          setAutocompleteData([])
        }
      }
    }
    window.addEventListener('click', panelDisplay)
    return () => {
      window.removeEventListener('click', panelDisplay)
    } 
  })
  

  return (
    <div className='navigation-wrapper' id='navigation'>
      <div className='bg-white'>
        <header className='relative bg-white border-b border-gray-200'>
          <nav aria-label='Top' className='sm:px-6 lg:px-0 max-w-7xl mx-auto'>
            {searchBox
              ? (
                <div className='px-4'>
                  <div className='h-16 flex items-center justify-center md:justify-end'>
                    <div className='w-full lg:w-4/6'>
                      <label htmlFor='search' className='sr-only'>
                        Search
                      </label>
                      <div className='relative flex items-center lg:ml-6'>
                        <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none ml-1.5'>
                          <SearchIcon
                            className='h-5 w-5 text-gray-400'
                            aria-hidden='true'
                          />
                        </div>
                        <input
                          id='search'
                          name='search'
                          className='block search-box w-full pr-3 pl-9 py-2 border border-gray-300 rounded-md leading-5 bg-white 
                            placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                          placeholder='Search'
                          type='search'
                          // onChange={filter}
                          // onKeyPress={(e) => handleKeyPress(e)}
                          autoFocus
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='px-4 pb-14 sm:px-0 sm:pb-0'>
                  <div className='h-18 flex items-center justify-evenly md:justify-between'>
                    {/* Logo */}
                    {navData?.logo?.url && (
                      <div>
                        <Link href='/'>
                          <span className='sr-only'>Workflow</span>
                          <Image
                            contents={{
                              src: navData.logo.url,
                              alt: navData.logo.title,
                              className: 'h-10  w-auto'
                            }}
                          />
                        </Link>
                      </div>
                    )}
                    {/* Flyout menus */}
                    <div id='navMenu' className='absolute bottom-0 justify-center md:justify-start inset-x-0 sm:static sm:flex-1 sm:self-stretch md:flex-1 flex'  >
                      {navData
                        ?<div onMouseLeave={handleClose} > 
                          <div className='h-16 items-center flex overflow-x-auto pb-px sm:h-full sm:border-t-0 sm:justify-center sm:overflow-visible sm:pb-0' >
                            

                            {navData?.menus?.length > 0
                           
                            && navData.menus.map((category, categoryIdx: number) => (
                              <div key={categoryIdx} className='flex'>
                                <>
                                  <div className={`py-6 relative flex menu${categoryIdx}`}>
                                    <div
                                      className={classNames(
                                        // categoryIdx === (navData?.menus?.length - 1) ? '' : styles.borderStyle,
                                        'text-base hover:text-blue ',
                                        `${styles.focusBtn} relative z-10 px-3 lg:px-6 flex items-center transition-colors ease-out duration-200 text-base font-medium`
                                      )}
                                    >
                                      <Link 
                                        onMouseEnter = {(e:MouseEvent) => navButton(e)}
                                        data-title={category?.title} id={category?.title}
                                        className={ classNames(`nav-btn ${styles.anchor} ${styles.textDecoration}`, category?.title===isShowPanel?`${styles.active}`:'') } 
                                        href={category?.url?.length > 0 && category?.url[0]?.url} 
                                      >
                                        {category?.title}
                                      </Link>
                                    </div>
                                  </div>
                            
                                  {/* {category?.sub_menu?.length > 0 
                                    ? <>
                                      <Transition
                                        show={isShowPanel===''?false:true}
                                        enter='transition-opacity duration-75'
                                        enterFrom='opacity-0'
                                        enterTo='opacity-100'
                                        leave='transition-opacity duration-150'
                                        leaveFrom='opacity-100'
                                        leaveTo='opacity-0'
                                      ><div  className={classNames(' absolute top-full inset-x-0 text-gray-500 sm:text-sm z-20', category.title === isShowPanel ? 'block' : 'hidden')} data-title={category.title}>
                                          <div className='absolute inset-0 top-1/2 bg-white shadow' aria-hidden='true' />
                                          <div className='relative bg-white z-10'>
                                            <div className='container'>
                                              <div className='grid grid-cols-2 items-start gap-y-10 gap-x-6 pt-10 pb-12 px-4 md:grid-cols-3 lg:gap-x-8'>
                                                <div>
                                                  <ul role='list' className='space-y-6 sm:space-y-4'
                                                  >
                                                    {category?.sub_menu?.length > 0
                                                    && category.sub_menu.map(
                                                      (item, ind:any) => (
                                                        
                                                        <li key={item.ind} onMouseEnter={(e) => onClick(e)} className='flex'>
                                                          {item.title
                                                            && <div id={ind} style={{ width: '-webkit-fill-available' }}>
                                                              <Link
                                                                id={ind}
                                                                href={item?.url[0]?.url && item.url[0].url}
                                                                className={classNames(
                                                                  `${styles.textDecoration} text-gray-700 hover:text-orange-800 text-base font-medium`,
                                                                  ind == isShow ? styles.activeLink : ''
                                                                )}
                                                              >
                                                                {item.title}
                                                              </Link>
                                                            </div>}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                                <div className='col-span-2 flex justify-between flex-col md:flex-row'>
                                                  <div className={styles.marginCss}>
                                                    <div className='sm:grid sm:grid-cols-1 sm:gap-x-6'>
                                                      {category.sub_menu.map(
                                                        (item, ind:any) => (
                                                          <ul
                                                            id={ind}
                                                            key={ind}
                                                            role='list'
                                                            className={ind == isShow ? `test pl-6 border-l border-gray-300 ${styles.ul} flex` : 'test pl-6 border-l border-gray-300 hidden'}
                                                          >
                                                            {item?.group?.length
                                                            > 0
                                                            && item.group.map(
                                                              (dt: any, i: any) => (
                                                                <div key={i} className={`flex flex-col ${styles.productList}`}>
                                                                  <li key={dt.title} className='flex pb-2 px-4'>
                                                                    <Link href={dt?.url[0]?.url && dt.url[0].url} className={`text-base text-purple-600 font-bold ${styles.textDecoration}`}
                                                                    >

                                                                      {dt.title}
                                                                    </Link>
                                                                  </li>
                                                                  <div className={styles.linkList}>
                                                                    {dt?.link?.length > 0
                                                                      && dt.link.map((ele: any, index: number) => (
                                                                        <li key={index} className='py-2 px-4'>
                                                                          <Link
                                                                            href={ele?.internal_url[0]?.url
                                                                              ? ele.internal_url[0].url
                                                                              : ele?.external_url
                                                                                ? ele.external_url
                                                                                : ''}
                                                                            className={`text-base text-gray-600 hover:text-gray-800 ${styles.textDecoration}`}>
                                                                            {ele.title}
                                                                          </Link>
                                                                        </li>
                                                                      ))}
                                                                  </div>
                                                                </div>
                                                              ))}
                                                          </ul>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                  {category?.sub_menu?.length > 0
                                                  && category.sub_menu.map((bn: any, index: any) => (
                                                    <ul
                                                      id={index}
                                                      key={index}
                                                      className={isShow === index ? 'test mt-6 md:mt-0 flex' : 'test mt-6 md:mt-0 hidden'}
                                                      data-id={isShow}
                                                    >
                                                      <li>
                                                        {bn?.banner[0]?.image
                                                          ? <Link className={styles.textDecoration} href={bn?.banner[0]?.banner_url[0]?.url}>
                                                            <Image
                                                              contents={{
                                                                src: bn.banner[0].image.url,
                                                                alt: bn.banner[0].image.title,
                                                                className: styles.menuImage
                                                              }} />
                                                            <p>{bn.banner[0].title}</p>
                                                          </Link>
                                                          : <Link className={styles.textDecoration}>
                                                            <Image
                                                              contents={{
                                                                src: '/static/images/automn-fits.jpg',
                                                                alt: 'automn-fits.jpg',
                                                                className: styles.menuImage
                                                              }} />
                                                          </Link>}
                                                      </li>

                                                    </ul>))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Transition>
                                    </>
                                    :''	
                                  } */}
                                </>
                              </div>
                            ))}
                          </div>
                        </div>
                        :<Shimmering/>}
                      
                    </div>

                     
                   
                    <div className='md:flex-1 flex items-center justify-end'>
                      {/* Search */}
                      <SearchIcon
                        className='h-5 text-gray-400'
                        aria-hidden='true'
                      />
                     
                    </div>
                  </div>
                </div>
              )}
          </nav>
        </header>
        <div id='searchPanel'>
          {searchBox ? <div>
            <SearchBox {...autocompleteData} />
          </div> : ''}
        </div>
      </div>
    </div>
  )
}

export default Navigation
